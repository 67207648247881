export default function Header(props){
    return (
        <div className="main-container">
            <div id="header-main" role="banner" className="header-block">
                <div className="banner-img">

                </div>

                <div className="logoWrapper no-text-decoration wrap" onClick={()=>props.onClick(0)}>
                    <img src="images/DB_70.jpg" alt="logo" /><br />
                    Information Technology Projects
                </div>
            </div>

            <nav className="navbar nav-block" role="navigation">	
                <ul className="navbar-nav">
                    <li className="nav-item"><button className="nav-item-button" onClick={()=>props.onClick(0)}>Oferta</button></li>
                    <li className="nav-item"><button className="nav-item-button" onClick={()=>props.onClick(1)}>Kontakt</button></li>
                </ul>
            </nav>
        </div>
    );
}