export default function Kontakt(props) {
    return (
        <div className="main-container">
            <main role="main" className="content-block">

                <article className="text-block" >
                    <header>
                        <h1>Kontakt</h1>
                    </header>

                    <section>
                        <span className="space-big">&nbsp;</span>

                        <p>
                            e-mail: <a href="mailto:db@db.net.pl" className="infoAdressText">db@db.net.pl</a><br />
                            tel: +48 602 422220 <br />
                            tel: +48 600 976555
                        </p>
                    </section>

                    <section>
                        <h2>Adres siedziby</h2>

                        <p>
                            DB Dariusz Jankowski<br />
                            ul. Zachodnia 11/26, 15-345 Białystok, Polska (Poland)<br />
                        </p>

                        <small>

                            <a style={{ color: '#0000FF', textAlign: 'left' }}
                                href="http://maps.google.pl/maps?f=q&amp;source=embed&amp;hl=pl&amp;geocode=&amp;q=DB+Dariusz+Jankowski,+Zachodnia+11,+Bia%C5%82ystok&amp;aq=0&amp;oq=db+dariusz+jankowski+bia%C5%82ystok+zachodnia+11&amp;sll=53.133075,23.14682&amp;sspn=0.051598,0.110378&amp;ie=UTF8&amp;hq=DB+Dariusz+Jankowski,&amp;hnear=Zachodnia+11,+Bia%C5%82ystok,+Podlaskie&amp;t=m&amp;cid=526397420277005770&amp;ll=53.114636,23.141477&amp;spn=0.006452,0.013797"
                                target="_blank"
                                rel="noreferrer">
                                Wyświetl mapę
                            </a>

                        </small>
                    </section>

                    <section>
                        <h2>Dane rejestracyjne</h2>

                        <p>
                            NIP: 542-203-24-91<br />
                            VAT UE: PL5422032491<br />
                            REGON: 050863736 <br />
                            Nr Rej.: 53599
                        </p>
                    </section>

                </article>
            </main>
        </div>
    );
}