import '../css/db-style.css';
import '../css/navbar.css';
import '../css/background.css';

export default function Oferta(props) {
    return (
        <div className="main-container">
            <main role="main" className="content-block">
                <article className="text-block">

                    <header>
                        <h1>Oferta</h1>
                    </header>

                    <section id="oferta">

                        <div className="content-width-set">
                            <p>Od 2000 roku jesteśmy dostawcą technologii IT w dziedzinie:</p>
                            <ul>
                                <li>gotowych, sprawdzonych na świecie rozwiązań,</li>
                                <li>własnych usług i oprogramowania.</li>
                            </ul>


                            <p>
                                Nasze rozwiązania przynoszą klientom wymierne korzyści finansowe poprzez redukowanie kosztów TCO
                                i poprawie wskaźnika ROI z każdej inwestycji. Dzięki nieustannemu udoskonalaniu naszej oferty, uwzględniającej aktualne potrzeby klientów i możliwości techniczno-organizacyjne,
                                możemy zagwarantować szybką obsługę i wysoką jakość proponowanych rozwiązań technologicznych IT.
                            </p>

                            <p>
                                Najważniejsze obszary działalności firmy:
                            </p>

                            <h2>Wytwarzenie oprogramowania na potrzeby klientów</h2>
                            <ul>
                                <li>Systemy projektowane od podstaw wg zamówienia klienta</li>
                                <li>Rozszerzenia funkcjonalności programów używanych przez klienta tzw.nakładki</li>
                                <li>Systemy analityczne, Hurtownie danych</li>
                                <li>
                                    Systemy EDI do współpracy z sieciami handlowymi: Metro (Real, Makro),
                                    Ahold, Tesco, PSS, SPAR, inne
                                </li>
                                <li>Integrowanie programów używanych przez klienta</li>
                                <li>System do prowadzenia mikro i małej firmy  - miniERP (KPiR, Rejestry VAT, Magazyn)</li>
                                <li>System do obsługi transportu w firmie</li>
                                <li>Sklepy internetowe</li>
                                <li>inne</li>

                            </ul>


                            <h2>Świadczenie usług teleinformatycznych</h2>
                            <ul>
                                <li>Analiza i projektowanie obiektowe procesów biznesowych, dotyczących zmian w systemie informatycznym, np. przygotowanie do wdrożenia systemu ERP.</li>
                                <li>Projektowanie struktur relacyjnych baz danych</li>
                                <li>Projektowanie, instalacja i administracja sieci komputerowych (LAN, WAN, Wi-Fi)</li>
                                <li>
                                    Instalacja i konfiguracja oprogramowania: systemy Microsoft, systemy
                                    antywirusowe
                                </li>
                                <li>
                                    Wdrażanie autorskiego rozwiązania EZDB - dostęp do baz naukowych on-line (pełnotekstowych
                                    czasopism i baz bibliograficzno-abstraktowych) z komputerów domowych pracowników naukowych
                                </li>
                                <li>Konfiguracja routerów (Cisco, D-Link, Linksys, inni)</li>
                                <li>Serwis systemów operacyjnych Microsoft oraz baz danych Microsoft SQL Server</li>
                                <li>Doradztwo i szkolenia w zakresie technologii IT</li>
                                <li>inne</li>

                            </ul>


                            <h2>
                                Sprzedaż i wdrażanie gotowych rozwiązań opartych o oprogramowanie i sprzęt komputerowy renomowanych producentów
                            </h2>
                            <ul>
                                <li>Microsoft®, Adobe®, Corel®, Symantec, McAfee®, Novell®</li>
                                <li>IBM®, HP, LG, OKI, SONY, Philips, Toshiba, Minolta </li>
                                <li>Cisco, Intel®, Kingston®, Seagate, Western Digital®, Symbol®</li>

                                <li>inne</li>
                            </ul>

                        </div>
                    </section>

                    <section id="zacheta">
                        <p>W celu nawiązania współpracy - prosimy o <button className="zacheta-button" onClick={()=>{props.onClick(1)}}>kontakt</button>.</p>
                    </section>

                </article>
            </main>
        </div>
    );
}

