import '../css/layout.css'

export default function Footer(){
    return (
        <div className="main-container">
            <footer role="contentinfo" className="footer-block">
                <small>Copyright © 2000-2024 by DB Dariusz Jankowski. Wszelkie prawa zastrzeżone.</small>
            </footer>
        </div>
    );
}