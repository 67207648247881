import Oferta from './pages/Oferta';
import Kontakt from './pages/Kontakt'
import Header from './pages/Header';
import Footer from './pages/Footer';
import {useState} from 'react';


function App() {
  
  const [state, setState] = useState(0); 

  const changeState = num => {
    setState(num);
  }
  
  return (
    <div className="page-container">
      <Header onClick={changeState} />
      {state === 0 ? <Oferta onClick={changeState}/> : <Kontakt onClick={changeState}/>}
      <Footer />
    </div>
  );
}

export default App;
